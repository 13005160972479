
import { computed, defineComponent, ref } from "vue";
import Datatable from "@/components/kt-datatable/Datatable.vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { Statuses } from "@/store/enums/StatusEnums";
import Swal from "sweetalert2/dist/sweetalert2.js";

export default defineComponent({
  name: "payment-records",
  props: {
    cardClasses: String,
    tableDataSubs: Object,
  },
  components: {
    Datatable,
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();

    const tableHeader = ref([
      {
        name: t("nameCourse"),
        key: "name",
        sortable: true,
      },
      {
        name: t("group"),
        key: "invoice",
        sortable: true,
      },
      {
        name: t("status"),
        key: "status",
        sortingField: "status.label",
        sortable: true,
      },
      {
        name: t("amount"),
        key: "amount",
        sortable: true,
      },
      {
        name: t("expired"),
        key: "date",
        sortable: true,
      },
      {
        name: t("actions"),
        key: "actions",
        sortable: false,
      },
    ]);
    const tableData = ref([
      {
        invoice:
          Math.floor(Math.random() * (9999 - 1000 + 1) + 1000) +
          "-" +
          Math.floor(Math.random() * (9999 - 1000 + 1) + 1000),
        description: "Payment for invoice",
        amount: "$880.00",
        status: {
          label: "debtor",
          state: "warning",
        },
        date: "21 Oct 2020, 5:54 pm",
      },
    ]);

    const deleteRecord = (invoice) => {
      for (let i = 0; i < tableData.value.length; i++) {
        if (tableData.value[i].invoice === invoice) {
          tableData.value.splice(i, 1);
        }
      }
    };

    /**
     * Put subscription status to locked
     */
    const pauseRecord = (id) => {

      Swal.fire({
        title: t('questionPause'),
        text: t('alertPause'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: t('yesPause'),
        cancelButtonText: t('noPause'),
      }).then((result) => {
        if (result.isConfirmed) {

          const data = {
            subscription_id: id
          };

          store
              .dispatch(Actions.SUBSCRIPTION_PUT_PAUSE, data)
              .then(() => {
                if (store.getters.userDetails._id) {
                  const userId = store.getters.userDetails._id;
                  store.dispatch(Actions.FETCH_USER, { id: userId });
                } else {
                  window.location.reload();
                }
                Swal.fire(
                    t('successPause'),
                    '',
                    'success'
                );
              })
              .catch(() => {
                Swal.fire({
                  text: 'Error',
                  icon: "error",
                  buttonsStyling: false,
                  confirmButtonText: t("tryAgain"),
                  customClass: {
                    confirmButton: "btn fw-bold btn-light-danger",
                  },
                });
              })

        }
      });

    };

    /**
     * Change subscription status to active if status is locked
     */
    const pauseOffRecord = (id) => {
      Swal.fire({
        title: t('questionPause'),
        text: t('alertPauseOff'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: t('pauseStart'),
        cancelButtonText: t('noPause'),
      }).then((result) => {
        if (result.isConfirmed) {

          const data = {
            subscription_id: id
          };

          store
              .dispatch(Actions.SUBSCRIPTION_PUT_PAUSE_OFF, data)
              .then(() => {
                if (store.getters.userDetails._id) {
                  const userId = store.getters.userDetails._id;
                  store.dispatch(Actions.FETCH_USER, { id: userId });
                } else {
                  window.location.reload();
                }
                Swal.fire(
                    t('successPauseOff'),
                    '',
                    'success'
                );
              })
              .catch(() => {
                Swal.fire({
                  text: 'Error',
                  icon: "error",
                  buttonsStyling: false,
                  confirmButtonText: t("tryAgain"),
                  customClass: {
                    confirmButton: "btn fw-bold btn-light-danger",
                  },
                });
              })

        }
      });
    };

    return { tableHeader, Statuses, tableData, deleteRecord, pauseRecord, pauseOffRecord, t };
  },
});
