
import { defineComponent, onMounted, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { hideModal } from "@/core/helpers/dom";
import * as Yup from "yup";
import {Actions} from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";

interface CardData {
  accountID: string;
  email: string;
  phone: string;
  notes: string;
}

export default defineComponent({
  name: "edit-users-details",
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  props: {
    accountID: String,
    email: String,
    phone: String,
    notes: String,
  },
  setup(props) {
    const store = useStore();
    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    const newCardModalRef = ref<null | HTMLElement>(null);
    const { t } = useI18n();

    const cardData = ref<CardData>({
      accountID: "",
      email: "",
      phone: "",
      notes: "",
    });

    const validationSchema = Yup.object().shape({
      email: Yup.string().required().label("Email"),
      phone: Yup.string().required().label("Phone"),
    });

    onMounted(() => {
      cardData.value.accountID = props.accountID ? props.accountID : "";
      cardData.value.email = props.email ? props.email : "";
      cardData.value.phone = props.phone ? props.phone : "";
      cardData.value.notes = props.notes ? props.notes : "";
    });

    const submit = () => {
      if (!submitButtonRef.value) {
        return;
      }

      //Disable button
      submitButtonRef.value.disabled = true;
      // Activate indicator
      submitButtonRef.value.setAttribute("data-kt-indicator", "on");

        let data = {
          _id: cardData.value.accountID,
          email: cardData.value.email,
          phone: cardData.value.phone,
          notes: cardData.value.notes,
        }

        store
            .dispatch(Actions.EDIT_USER_DETAILS, data)
            .then(() => {
              store.dispatch(Actions.FETCH_USER, { id: data._id })
                  .then(() => {
                if (submitButtonRef.value) {
                  submitButtonRef.value.disabled = false;

                  submitButtonRef.value?.removeAttribute("data-kt-indicator");
                }
                Swal.fire({
                  text: t("processDone"),
                  icon: "success",
                  buttonsStyling: false,
                  confirmButtonText: t("good"),
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                }).then(() => {
                  hideModal(newCardModalRef.value);
                });
              });
            })
            .catch(() => {
              Swal.fire({
                text: 'Error',
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: t("tryAgain"),
                customClass: {
                  confirmButton: "btn fw-bold btn-light-danger",
                },
              });
            })
    };

    return {
      cardData,
      validationSchema,
      submit,
      submitButtonRef,
      newCardModalRef,
      t,
    };
  },
});
