
import { defineComponent, ref } from "vue";
import Datatable from "@/components/kt-datatable/Datatable.vue";
import { InvoicesTableItem } from "@/assets/ts/components/InvoicesComponent";
import { replaceMonth } from "@/store/enums/months";
import { useI18n } from "vue-i18n";
import config from "@/core/config/APIConfig";

interface invoicesData {
  date: string
  payment_id: string
  status: string
  state: string
  payment_cost: string
}

export default defineComponent({
  name: "invoices",
  props: {
    cardClasses: String,
    tableDataInvoices: Object,
  },
  components: {
    Datatable,
  },
  data() {
    return {
      years: [
        { date: new Date().getFullYear() },
        { date: new Date().getFullYear() - 1 },
        { date: new Date().getFullYear() - 2 },
        { date: new Date().getFullYear() - 3 },
      ],
    };
  },
  setup() {
    const { t } = useI18n();
    const tableHeader = ref([
      {
        name: t("paymentId"),
        key: "order",
        sortable: true,
      },
      {
        name: t("nameCourse"),
        key: "course",
        sortable: true,
      },
      {
        name: t("summa"),
        key: "amount",
        sortable: true,
      },
      {
        name: t("status"),
        key: "status",
        sortingField: "status.label",
        sortable: true,
      },
      {
        name: t("date"),
        key: "date",
        sortable: true,
      },
      {
        name: t("invoice"),
        key: "invoice",
        sortable: false,
      },
    ]);

    const filterTable = (ordersList, year) => {
      const arr: invoicesData[] = [];

      ordersList.forEach((item) => {
        // TODO: course name to column
        if (item) {
          const dat  = item?.date ? item.date.slice(-4) : "";
          if (dat == year.date) {
            const it = {
              date: item?.date ? item.date : "",
              payment_id: item?.payment_id ? item.payment_id : "",
              status: item?.status ? item.status : "",
              state: item.status === ("Failed" && "Canceled") ? "danger" : "success",
              payment_cost: item?.payment_cost ? item.payment_cost : "",
            };
            arr.push(it);
          }
        }
      })
      return arr;
    };

    const goToPaymentService = (paymentId) => {
      if (paymentId && config.yookassaSearch) {
        window.open(config.yookassaSearch + paymentId, "_blank");
      }
    };

    return { tableHeader, filterTable, t, goToPaymentService };
  },
});
