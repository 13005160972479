
import { defineComponent, onMounted, computed, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components";
import Dropdown3 from "@/components/dropdown/Dropdown3.vue";
import EditUsersDetails from "@/components/modals/forms/EditUsersDetails.vue";
import PaymentRecords from "@/components/customers/cards/overview/PaymentRecords.vue";
import PaymentMethods from "@/components/customers/cards/overview/PaymentMethods.vue";

import Invoices from "@/components/customers/cards/overview/Invoices.vue";
import { statusState, detectCard } from "@/core/helpers/converters";
import Events from "@/components/customers/cards/events-and-logs/Events.vue";
import Logs from "@/components/customers/cards/events-and-logs/Logs.vue";
import { Actions } from "@/store/enums/StoreEnums";
import { useRouter, onBeforeRouteUpdate } from "vue-router";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import moment from "moment";
import store from "@/store";

export default defineComponent({
  name: "customer-details",
  components: {
    PaymentRecords,
    PaymentMethods,
    Invoices,
    Events,
    Logs,
    Dropdown3,
    EditUsersDetails,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const { t } = useI18n();
    const getCurses = ref([]);

    onMounted(() => {
      MenuComponent.reinitialization();
      setCurrentPageBreadcrumbs(store.getters.userDetails.fname + " " + store.getters.userDetails.lname, [t("users")]);

      /**
       * Get all courses array
       */
      const courses = store.getters.getAllCurses;
      const arr = courses.map((item) => {
        return {
          _id: item._id,
          course_name: item.course_name ? item.course_name : "-",
          course_description: item.course_description
            ? item.course_description
            : "-",
          course_icon: item.course_icon ? item.course_icon : "-",
          __v: item.__v ? item.__v : 0,
          payment_cost: item.payment_cost ? item.payment_cost : "-",
        };
      });
      getCurses.value = arr;

    });

    const getUser = computed(() => {
      return store.getters.userDetails ? store.getters.userDetails : {};
    });

    const ordersSum = computed(() => {
      let orders = store.getters.userDetails.subscriptions
        ? [...store.getters.userDetails.subscriptions]
        : [];
      return orders.reduce(
        (total, subscription) =>
          total + Math.trunc(parseFloat(subscription.payment_cost) * 100) / 100,
        0
      );
    });

    const subscriptions = computed(() => {
      let subsArray = store.getters.userDetails.subscriptions
        ? [...store.getters.userDetails.subscriptions]
        : [];

      subsArray = subsArray.map((item) => {
        return {
          id: item._id,
          name: getCourseName(item.course_id),
          group_name: item.group_name ? item.group_name : "-",
          //status: item.status.replace(/\b\w/g, (l) => l.toUpperCase()),
          status: item.status,
          state: statusState(item.status),
          payment_cost: item.payment_cost,
          date_expired: moment(item.date_expired).format("DD MMM YYYY, h:mm a"),
        };
      });
      return subsArray;
    });

    const cardData = computed(() => {
      if (!store.getters.userDetails.subscriptions) return;

      let cardArray = [...store.getters.userDetails.subscriptions],
        cardIDs: any[] = [],
        cardArrayResult: any[] = [];

      cardArray.forEach((item) => {
        if (!cardIDs.includes(item.card_last4)) {
          cardIDs.push(item.card_last4);
          cardArrayResult.push(item);
        }
      });

      cardArrayResult = cardArrayResult.map((item) => {
        return {
          card_expire_month: item.card_expiry_month,
          card_expire_month_format: moment(item.card_expiry_month).format(
            "MMM"
          ),
          card_expiry_year: item.card_expiry_year,
          card_first6: item.card_first6,
          card_last4: item.card_last4,
        };
      });
      return cardArrayResult;
    });

    const ordersData = computed(() => {
      if (!store.getters.userDetails.orders) return;

      let ordersArray = [...store.getters.userDetails.orders].map((item) => {
        return {
          payment_id: item.payment_id,
          payment_cost: item.payment_cost,
          status: item.status.replace(/\b\w/g, (l) => l.toUpperCase()),
          state: statusState(item.status),
          date: moment(item.createdAt).format("MMM DD, YYYY"),
        };
      });
      return ordersArray;
    });

    const subscriptionViewClick = (id) => {
      router.push({ name: "subscription", params: { id: id } });
    };

    /**
     * Find course name by course ID
     */
    const getCourseName = (courseId) => {
      let coursesArr = getCurses.value,
          courseName,
          courseObj;
      if (coursesArr.length) {
        courseObj = coursesArr.find(item => courseId === item['_id']);
        courseName = courseObj?.course_name ?? "";
      }
      return courseName;
    };

    return {
      getUser,
      ordersSum,
      subscriptions,
      statusState,
      detectCard,
      cardData,
      ordersData,
      subscriptionViewClick,
      t,
    };
  },
});
